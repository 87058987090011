import { useEffect, useRef, useState } from 'react'
import { ClickAwayListener, Grow, Icon, IconButton, MenuList, Paper, Popper, Badge } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Notifications } from 'src/components/Notifications'
import { Typography } from 'src/components/atoms/Typography'
import { useGetAllNotifications, useReadAllNotifications, useReadNotification } from 'src/hooks/useNotifications'
import { InfiniteData } from 'react-query'
import { IPagination } from 'src/services/types/common'
import { INotification } from 'src/services/Notification/interfaces'

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: '8px 16px',
    minWidth: 300,
    maxWidth: 450,
    [theme.breakpoints.only('xs')]: {
      minWidth: 0,
    },
  },
  title: {
    marginBottom: 10,
  },
}))

const NotificationsMenu = () => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles({ open })
  const [page, setPage] = useState(1)
  const {
    data,
    fetchNextPage,
    hasMore,
    isLoading,
  } = useGetAllNotifications(page)
  const [notifications, setNotifications] = useState<InfiniteData<IPagination<INotification[]>>>(data)
  const { readAll } = useReadAllNotifications()
  const { readOne } = useReadNotification()
  const [unread, setUnRead] = useState(0)

  const handleCalculateUnread = (pages) => {
    let count = 0
    pages?.forEach((item) => {
      count += item?.results.filter((n) => n.unread).length
    })
    setUnRead(count)
  }

  const handleReadAll = async () => {
    if (unread > 0) {
      await readAll()
      handleCalculateUnread(notifications?.pages)
    }
  }

  const handleLoadMore = async () => {
    if (hasMore) {
      const newPage = page + 1
      await fetchNextPage()
      setPage(newPage)
    }
  }

  const handleClose = (event: any): void => {
    if (open) {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
      setOpen(false)
    }
  }

  const handleClick = () => setOpen((v) => !v)

  const handleClickNotification = async (e, id) => {
    await readOne(id)
    handleClose(e)
  }

  useEffect(() => {
    if (data?.pages?.length) {
      setNotifications(data)
      handleCalculateUnread(data?.pages)
    }
  }, [data])

  return (
    <>
      {unread > 0 ? (
        <IconButton
          ref={anchorRef}
          className="icon"
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <Badge variant="dot" color="error">
            <Icon>notifications</Icon>
          </Badge>
        </IconButton>
      ) : (
        <IconButton
          ref={anchorRef}
          className="icon"
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          <Icon>notifications</Icon>
        </IconButton>
      )}
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end">
        {({
          TransitionProps,
          placement,
        }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}
          >
            <Paper classes={{ root: classes.menu }} elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <Typography classes={{ root: classes.title }} variant="h6">Notificaciones</Typography>
                  <Notifications
                    hasMore={hasMore}
                    isLoading={isLoading}
                    onClick={handleClickNotification}
                    onLoadMore={handleLoadMore}
                    onReadAll={handleReadAll}
                    paginatedNotifications={notifications}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default NotificationsMenu
