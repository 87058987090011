import { Icon, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from 'src/components/atoms/Typography'

const useStyles = makeStyles({
  icon: {
    fontSize: 80,
    color: 'gray',
  },
})

export const EmptyNotifications = () => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
      <Icon classes={{ root: classes.icon }}>notifications_off</Icon>
      <Typography variant="subtitle1">No hay notificaciones</Typography>
    </Grid>
  )
}
