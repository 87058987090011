import React, { FC } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Link from 'next/link'
import { Image } from 'src/components/atoms/Image'
import { MenuMobile } from 'src/components/templates/Landing/MenuMobile'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
  },
  toolBar: {
    padding: 0,
  },
  logo: {
    cursor: 'pointer',
    paddingLeft: 15,
  },
  headerRightItems: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuItem: {
    textDecoration: 'none',
    margin: '0 15px',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  menuItemRegisterButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: '7px 20px',
    fontWeight: 500,
    borderRadius: 15,
    '&.menu': {
      backgroundColor: theme.palette.primary.main,
      color: '#2D2D2D',
    },
  },
}))

interface HeaderPublicProps {
  menu?: { url: string, title: string, newTab?: boolean }[]
}

const HeaderPublic: FC<HeaderPublicProps> = ({ menu }) => {
  const classes = useStyles()

  return (
    <AppBar
      position="fixed"
      className={classes.header}
    >
      <Toolbar className={classes.toolBar}>
        <Link href="/" passHref>
          <div className={classes.logo}>
            <Image
              layout="fixed"
              height={30}
              width={170}
              alt="logo"
              src="/images/landing/logo-fondo-naranja.png"
            />
          </div>
        </Link>

        <div className={classes.headerRightItems}>
          <nav className={classes.menu}>
            {
              menu?.map((item, i) => (
                <Typography
                  key={i}
                  component="a"
                  href={item.url}
                  target={item.newTab ? '_blank' : ''}
                  className={classes.menuItem}
                >
                  {item.title}
                </Typography>
              ))
            }
            <Typography component="a" href="/login" classes={{ root: classes.menuItem }}> Inicia sesión </Typography>
            <Link href="/register" passHref>
              <Typography
                component="a"
                classes={{ root: `${classes.menuItem} ${classes.menuItemRegisterButton}` }}
              >
                Regístrate
              </Typography>
            </Link>
          </nav>

          <MenuMobile menu={menu} />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default HeaderPublic

// import { AppBar, Grid, Toolbar, Hidden } from '@mui/material'
// import makeStyles from '@mui/styles/makeStyles'
// import { Typography } from 'src/components/atoms/Typography'
// import { Image } from 'src/components/atoms/Image'
// import { FC } from 'react'
// import Link from 'next/link'
// import { MenuMobile } from 'src/components/templates/Landing/MenuMobile'

// const useStyles = makeStyles((theme) => ({
//   header: {
//     background: `${theme.palette.primary.main} !important`,
//     height: 65,
//   },
//   toolbar: {
//     padding: 0,
//   },
//   container: {
//     marginLeft: 20,
//     marginTop: 3,
//   },
//   menu: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     alignItems: 'center',
//   },
//   logo: {
//     width: 'auto !important',
//     height: '100% !important',
//     cursor: 'pointer',
//   },
//   item: {
//     textDecoration: 'none',
//     margin: '0 15px',
//     cursor: 'pointer',
//     color: theme.palette.primary.contrastText,
//     fontWeight: 500,
//   },
//   itemRegister: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.primary.main,
//     padding: '7px 20px',
//     fontWeight: 500,
//     borderRadius: 15,
//     '&.menu': {
//       backgroundColor: theme.palette.primary.main,
//       color: '#2D2D2D',
//     },
//   },
// }))

// interface Props {
//   menu?: { url: string, title: string }[]
// }

// const Header: FC<Props> = ({ menu }) => {
//   const classes = useStyles()

//   return (
//     <AppBar position="fixed" elevation={0} className={classes.header}>
//       <Toolbar className={classes.toolbar}>
//         <Grid container justifyContent="space-between" alignItems="center" className={classes.container}>
//           <Link href="/" passHref>
//             <Grid item md={2}>
//               <Image
//                 layout="fixed"
//                 height={30}
//                 width={170}
//                 alt="logo"
//                 src="/images/landing/logo-fondo-naranja.png"
//                 className={classes.logo}
//               />
//             </Grid>
//           </Link>
//           <Hidden mdDown>
//             <Grid component="nav" md={10} className={classes.menu}>
//               {
//                 menu?.map((item, i) => (
//                   <Typography
//                     key={i}
//                     component="a"
//                     href={item.url}
//                     className={classes.item}
//                   >
//                     {item.title}
//                   </Typography>
//                 ))
//               }
//               <Typography component="a" href="/login" classes={{ root: classes.item }}> Inicia sesión </Typography>
//               <Link href="/register" passHref>
//                 <Typography
//                   component="a"
//                   classes={{ root: `${classes.item} ${classes.itemRegister}` }}
//                 >
//                   Regístrate
//                 </Typography>
//               </Link>
//             </Grid>
//           </Hidden>
//           <Hidden mdUp>
//             <MenuMobile menu={menu} />
//           </Hidden>
//         </Grid>
//       </Toolbar>
//     </AppBar>
//   )
// }

// export default Header
