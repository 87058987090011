import { Action, LOAD_SHOPPING_CART } from 'src/store/modules/shop/types'
import * as ShoppingCartService from 'src/services/ShoppingCart'

export const addToShoppingCart = (type: string, slug: string): Action => async (dispatch, getState) => {
  const { shoppingCart } = getState().shop
  const newCartResult = await ShoppingCartService.create(type, slug)
  const newCart = [...shoppingCart.cart, { ...newCartResult }]
  dispatch({ type: LOAD_SHOPPING_CART, payload: { ...shoppingCart, cart: newCart } })
}

export const removeToShoppingCart = (slug: string): Action => async (dispatch, getState) => {
  const { shoppingCart } = getState().shop
  const product = [...shoppingCart.cart].find((prod) => prod.slug === slug)
  if (!!product) {
    await ShoppingCartService.remove(product.id)
    const cart = [...shoppingCart.cart].filter((prod) => prod.slug !== slug)
    dispatch({ type: LOAD_SHOPPING_CART, payload: { ...shoppingCart, cart } })
  }
}

export const loadShoppingCart = (cart): Action => async (dispatch) => {
  dispatch({ type: LOAD_SHOPPING_CART, payload: cart })
}
