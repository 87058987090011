import { FC, Fragment } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { INotification } from 'src/services/Notification/interfaces'
import { Button } from 'src/components/atoms/Button'
import { Typography } from 'src/components/atoms/Typography'
import { InfiniteData } from 'react-query'
import { IPagination } from 'src/services/types/common'
import { EmptyNotifications } from './EmptyNotifications'
import { Notification } from './Notification'

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 4,
  },
  title: {
    fontWeight: 'bolder',
  },
  subtitle: {
    cursor: 'pointer',
  },
  list: {
    width: '100% !important',
    margin: 0,
    maxHeight: 350,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: '0.4em',
    },
  },
  loadMore: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

interface IProps {
  onClick: (e, id: number) => void
  onLoadMore: () => void
  onReadAll: () => void
  isLoading: boolean
  hasMore: boolean
  paginatedNotifications: InfiniteData<IPagination<INotification[]>>
}

export const Notifications: FC<IProps> = ({ onClick, onLoadMore, isLoading, paginatedNotifications, hasMore, onReadAll }) => {
  const classes = useStyles()

  if (!paginatedNotifications || paginatedNotifications?.pages?.length === 0) return <EmptyNotifications />

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container justifyContent="space-between" alignItems="center" classes={{ root: classes.header }}>
        <Grid classes={{ root: classes.title }} component={Typography} item>Recientes</Grid>
        <Grid
          component={Typography}
          item
          variant="caption"
          classes={{ root: classes.subtitle }}
          className="no-select"
          onClick={onReadAll}
        >
          Marcar como leído
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        classes={{ root: classes.list }}
      >
        {
          paginatedNotifications?.pages?.map((item, i) => (
            <Fragment key={i}>
              {
                item?.results?.map((notification) => (
                  <Notification key={notification.id} notification={notification} onClick={onClick} />
                ))
              }
            </Fragment>
          ))
        }
        {
          hasMore && (
            <Grid item xs={12} classes={{ root: classes.loadMore }}>
              <Button disabled={isLoading} onClick={onLoadMore} fullWidth color="secondary" variant="text">Cargar más</Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Grid>
          )
        }
      </Grid>
    </Grid>
  )
}
