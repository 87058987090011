import Axios from './Axios'
import { IPayuInfo, IPrice } from './interfaces'
import Uris from './Uris'
import formatUri from '../utils/formatUri'
import { ITutorial } from './Tutorials/interfaces'

export interface ICart extends ITutorial{
  id: number
}

export interface IShoppingCart {
  cart: ICart[]
  payu: IPayuInfo
  total: IPrice
}

export const getShoppingCart = async (): Promise<IShoppingCart> => {
  const uri = Uris.shoppingCart.getShoppingCart
  const { details, total, payu } = await Axios.get(uri)
  const cart = [...details].map(({ product, id }) => ({ ...product, id }))
  return { cart, payu, total }
}

export const create = async (type: string, item: string): Promise<ICart> => {
  const uri = Uris.shoppingCart.createShoppingCart
  const { id, product } = await Axios.post(uri, { type, item })
  return { ...product, id }
}

export const remove = async (id) => {
  const uri = formatUri(Uris.shoppingCart.removeShoppingCart, id)
  return Axios.delete(uri)
}
