import Uris from 'src/services/Uris'
import { getShoppingCart, IShoppingCart } from 'src/services/ShoppingCart'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useFetchData from './useFetchData'
import { loadShoppingCart } from '../store/modules/shop/actions'
import { useSelector } from '../store/types'

export const useGetShoppingCart = ({ enabled = true, withReload = false }): IShoppingCart => {
  const dispatch = useDispatch()
  const cart = useSelector(({ shop }) => shop?.shoppingCart)
  const { data } = useFetchData<IShoppingCart>({
    uri: Uris.shoppingCart.getShoppingCart,
    fetcher: () => getShoppingCart(),
    args: withReload ? cart?.cart?.length : 0,
    enabled,
  })

  useEffect(() => {
    if (data) {
      dispatch(loadShoppingCart(data))
    }
  }, [data])

  return cart
}
