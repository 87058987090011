import Uris from 'src/services/Uris'
import { IPagination } from 'src/services/types/common'
import { INotification } from 'src/services/Notification/interfaces'
import { useMutation } from 'react-query'
import { getAllNotifications, readAllNotifications, readNotification } from 'src/services/Notification'
import { useInfiniteFetchData } from './useFetchData'

export const useGetAllNotifications = (page: number) => {
  const { hasNextPage: hasMore, data, fetchNextPage, isLoading } = useInfiniteFetchData<IPagination<INotification[]>>({
    uri: Uris.notifications.getAll,
    fetcher: ({ pageParam }) => {
      if (pageParam) return getAllNotifications(pageParam)
      return getAllNotifications()
    },
    args: page,
    enabled: !!page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.next) {
        const query = new URLSearchParams(lastPage?.next)
        const queryParams = Object.fromEntries(query.entries())
        if (queryParams?.page) return queryParams.page
      }
      return null
    },
  })

  return { hasMore, data, fetchNextPage, isLoading }
}

export const useReadAllNotifications = () => {
  const mutation = useMutation(readAllNotifications)

  const { isSuccess } = mutation

  const readAll = mutation.mutate

  return { readAll, isSuccess }
}

export const useReadNotification = () => {
  const mutation = useMutation(readNotification)

  const { isSuccess } = mutation

  const readOne = (id: number) => mutation.mutate(id)

  return { readOne, isSuccess }
}
