import { Grid, Icon, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useRef, useState } from 'react'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { Typography } from 'src/components/atoms/Typography'
import Link from 'next/link'
import { useSelector } from 'src/store/types'

const useStyles = makeStyles((theme) => ({
  item: {
    textDecoration: 'none',
  },
  itemMenuContainer: {
    margin: '10px 0',
  },
  itemMenu: {
    textDecoration: 'none',
    margin: 10,
    cursor: 'pointer',
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  itemRegister: {
    backgroundColor: theme.palette.primary.contrastText,
    color: `${theme.palette.common.white} !important`,
    padding: '8px 10px',
    borderRadius: 10,
    '&.menu': {
      backgroundColor: theme.palette.primary.main,
      color: '#2D2D2D',
    },
  },
  iconButton: {
    display: 'none',
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  menu: {
    display: 'grid',
    textAlign: 'center',
    padding: '15px 10px',
    position: 'absolute',
    backgroundColor: '#2D2D2D',
    width: 200,
    top: 0,
    right: -200,
    transitionDuration: '.5s',
    '&.open': {
      transitionDuration: '.5s',
      right: 0,
    },
  },
  login: {
    marginBottom: 0,
    paddingBottom: 10,
    position: 'relative',
    '&:after': {
      content: '\'\'',
      width: '100%',
      height: 2,
      backgroundColor: 'rgba(255,255,255,.3)',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  },
  iconMenu: {
    color: theme.palette.primary.contrastText,
  },
}))

interface Props {
  menu?: { url: string, title: string }[]
}

export const MenuMobile: FC<Props> = ({ menu }) => {
  const classes = useStyles()
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const isPublic = useSelector(({ general }) => general.isPublic)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  useOnClickOutside(ref, handleClose)

  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleOpen}>
        <Icon className={classes.iconMenu}>menu</Icon>
      </IconButton>

      <Grid
        ref={ref}
        container
        classes={{ root: `${classes.menu} ${open ? 'open' : ''}` }}
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Link href="/register" passHref>
            <Typography classes={{ root: `${classes.itemMenu} ${classes.itemRegister} menu` }}> Regístrate </Typography>
          </Link>
        </Grid>
        <Grid item classes={{ root: classes.login }}>
          <Link href="/login" passHref>
            <Typography classes={{ root: classes.itemMenu }}>Inicia sesión </Typography>
          </Link>
        </Grid>
        <Grid item>
          <Grid item className={`${classes.itemMenuContainer} ${classes.login}`}>
            {
              menu && menu.map((item, key) => (
                <Grid item className={classes.itemMenuContainer} key={key}>
                  <Typography
                    onClick={handleClose}
                    component="a"
                    href={item.url}
                    className={classes.itemMenu}
                  >
                    {item.title}
                  </Typography>
                </Grid>
              ))
            }
            {!isPublic && (
              <>
                <Grid item>
                  <Link href="/tienda" passHref>
                    <Typography classes={{ root: classes.itemMenu }}>Tienda </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/tienda/carrito" passHref>
                    <Typography classes={{ root: classes.itemMenu }}>Carrito de compras </Typography>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
