import { NavigationActionTypes } from 'src/store/modules/navigation/types/actions'
import { Action, CHANGE_MINI, CHANGE_OPEN, CHANGE_OPEN_MOBILE } from './types'

export const changeMini = (value): NavigationActionTypes => ({
  type: CHANGE_MINI,
  payload: value,
})

export const changeOpen = (value): Action => (dispatch) => {
  dispatch({ type: CHANGE_OPEN, payload: value })
  dispatch({ type: CHANGE_MINI, payload: !value })
}

export const changeOpenMobile = (value): NavigationActionTypes => ({
  type: CHANGE_OPEN_MOBILE,
  payload: value,
})
