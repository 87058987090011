import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Link from 'next/link'
import { INotification } from 'src/services/Notification/interfaces'
import { FC } from 'react'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import es from 'date-fns/locale/es'
import { Typography } from 'src/components/atoms/Typography'
import { Image } from 'src/components/atoms/Image'

const useStyles = makeStyles((theme) => ({
  item: {
    padding: '8px 0 !important',
    width: '100%',
    cursor: 'pointer',
  },
  image: {
    '& img': {
      borderRadius: 10,
      maxWidth: '100%',
      maxHeight: 110,
      minWidth: 80,
      minHeight: 80,
    },
  },
  message: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 2,
    overflow: 'hidden',
  },
  italic: {
    fontStyle: 'italic',
  },
  dot: {
    alignSelf: 'center',
    '& > div': {
      width: 15,
      height: 15,
      borderRadius: 15,
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

interface IProps {
  notification: INotification
  onClick: (e, id: number) => void
}

export const Notification: FC<IProps> = ({ notification, onClick }) => {
  const classes = useStyles()

  const handleRead = (e) => {
    onClick(e, notification.id)
  }

  const getUrl = () => {
    if (notification.verb !== 'expired_subscription') return notification.data.nextUrl
    if (notification.data.objectType === 'level') return `${notification.data.nextUrl}?openLevel=${notification.data.slug}`
    return `${notification.data.nextUrl}?openCourse=true`
  }

  return (
    <Link href={getUrl()} passHref>
      <Grid item container spacing={2} classes={{ root: classes.item }} onClick={handleRead}>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={3} className={classes.image}>
          <Image
            src={notification.data.imageUrl}
            alt="img"
          />
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={8} xs={8} container direction="column">
          <Grid item classes={{ root: classes.message }}>
            <Typography dangerouslySetInnerHTML={{ __html: notification.description }} />
          </Grid>
          <Grid>
            <Typography variant="caption" classes={{ root: classes.italic }}>Haz clic para empezar</Typography>
          </Grid>
          <Grid>
            <Typography color="primary" variant="caption" classes={{ root: classes.italic }}>
              {formatDistanceToNow(parseISO(notification.timestamp), { addSuffix: true, locale: es })}
            </Typography>
          </Grid>
        </Grid>
        {notification.unread && (
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className={classes.dot}>
            <div />
          </Grid>
        )}
      </Grid>
    </Link>
  )
}
