import { useState, useRef, FC } from 'react'
import {
  MenuItem,
  ListItemAvatar,
  Icon,
  IconButton,
  ClickAwayListener,
  Avatar,
  Divider,
  Badge,
  Popper,
  Paper,
  Grow,
  MenuList,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Link from 'next/link'
import { Typography } from 'src/components/atoms/Typography'

const useStyles = makeStyles((theme: any) => ({
  menu: {
    maxWidth: 250,
    '& .MuiList-padding': {
      paddingBottom: 0,
    },
    '& .items': {
      padding: 8,
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 30px',
    '& .user': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& .user .role': {
      fontWeight: 700,
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 15px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& span': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  itemIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  icon: {
    color: theme.palette.secondary.main,
  },
}))

interface IProps {
  name: string
  email: string
  role: string
  image: string
  handleLogout: () => void
  isVerified: boolean
}

const UserMenu: FC<IProps> = ({
  name,
  email,
  role,
  image,
  handleLogout,
  isVerified,
}) => {
  const classes = useStyles()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen((v) => !v)

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
    setOpen(false)
  }

  const handleClickLogout = () => {
    handleLogout()
    setOpen(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        className="icon"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
      >
        {!isVerified ? (
          <Badge overlap="circular" variant="dot" color="error">
            <Icon>account_circle</Icon>
          </Badge>
        ) : (
          <Icon>account_circle</Icon>
        )}
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-end">
        {({
          TransitionProps,
          placement,
        }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom' }}
          >
            <Paper classes={{ root: classes.menu }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <div className={classes.userInfo}>
                    <ListItemAvatar className="image">
                      {!!image ? <Avatar src={image} alt="user-image" /> :
                      <Avatar src="/images/profile.png" alt="user-image" />}
                    </ListItemAvatar>
                    <div className="user">
                      <Typography className="name" component="div" variant="inherit" noWrap>
                        {name}
                      </Typography>
                      <Typography className="email" component="div" variant="inherit" noWrap>
                        {email}
                      </Typography>
                      <Typography className="role" component="div" variant="inherit" noWrap>
                        {role}
                      </Typography>
                    </div>
                  </div>
                  <Divider />
                  <div className="items">
                    <Link href="/profile" passHref>
                      {
                        !isVerified ? (
                          <MenuItem
                            className={classes.item}
                            onClick={handleClose}
                          >
                            <div className={classes.itemIconWrapper}>
                              <Icon className={classes.icon}>account_circle</Icon>
                              <span className="itemTitle">Ver perfil</span>
                            </div>

                            <Badge overlap="circular" variant="dot" color="error" />
                          </MenuItem>
                        ) : (
                          <MenuItem className={classes.item} onClick={handleClose}>
                            <div className={classes.itemIconWrapper}>
                              <Icon className={classes.icon}>account_circle</Icon>
                              <span>Ver perfil</span>
                            </div>
                          </MenuItem>
                        )
                      }
                    </Link>
                    <MenuItem className={classes.item} onClick={handleClickLogout}>
                      <div className={classes.itemIconWrapper}>
                        <Icon className={classes.icon}>exit_to_app</Icon>
                        <span>Cerrar sesión</span>
                      </div>
                    </MenuItem>
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default UserMenu
