const translateRole = (role?: string) => {
  switch (role) {
    case 'student':
      return 'Estudiante'
    case 'admin':
      return 'Administrador'
    default:
      return ''
  }
}

export default translateRole
