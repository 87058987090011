import Axios from 'src/services/Axios'
import { IPagination } from 'src/services/types/common'
import Uris from 'src/services/Uris'
import formatUri from 'src/utils/formatUri'
import { INotification } from './interfaces'

export const getAllNotifications = (page = 1): Promise<IPagination<INotification[]>> => {
  const uri = Uris.notifications.getAll
  return Axios.get(uri, { params: { page, size: 100 } })
}

export const readNotification = (id: number): Promise<void> => {
  const uri = formatUri(Uris.notifications.readOne, id)
  return Axios.put(uri, {})
}

export const readAllNotifications = (): Promise<void> => {
  const uri = Uris.notifications.readAll
  return Axios.put(uri, {})
}
